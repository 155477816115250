import DefaultPage from '../../05_templates/Default';
import React from 'react';
import urlFetch from '../../00_utilities/connectivity/urlFetch';
import { useParams } from 'react-router-dom';
import { Context } from '../../00_utilities/context';
import styled from 'styled-components';
import Button from '../../02_atoms/Button';
import ComponentContainer from '../../01_base/layout/ComponentContainer';
import ContentContainer from '../../01_base/layout/ContentContainer';
import HomeLink from '../../02_atoms/HomeLink';
import QuantitySelect from '../../02_atoms/QuantitySelect';
import { ReactComponent as CartIcon } from '../../../icons/cart.svg';
import AddedProductPopup from '../../03_molecules/AddedProductPopup';
import NotSamePaidTypePopup from '../../03_molecules/NotSamePaidTypePopup';
import ProductInfoTable from '../../03_molecules/ProductInfoTable';
import ProductCard from '../../03_molecules/ProductCard';
import ProductCardsContainer from '../../01_base/layout/ProductCardsContainer';
import LoadingIcon from '../../02_atoms/LoadingIcon';
import ImageSlider from '../../03_molecules/ImageSlider';
import SizeSelect from "../../02_atoms/SizeSelect";

const CustomComponentContainer = styled(ComponentContainer)`
    @media (max-width: ${props => props.theme.breakpoints.lg.breakpoint - 1}px) {
        padding-top: 0;
        
        .home-link-container {
            top: 310px;
        }
    }
    
    @media (max-width: ${props => props.theme.breakpoints.md.breakpoint - 1}px) {
        padding-top: 0;

        .home-link-container {
            top: 210px;
        }
    }
    
    padding-top: 135px;
`;

const MoreComponentContainer = styled(ComponentContainer)`
    background-color: #F5F5F5;
`;

const StyledProductContainer = styled('div')`
    display: flex;
    flex-direction: column;

    @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
        flex-direction: row;
    }
`;

const StyledCategoryTagsContainer = styled('div')`
    position: absolute;
    top: -40px;
    padding-right: 15px;
    width: calc(100% - 30px);
    
    span {
        border-radius: 4px;
        background-color: #DFDEDF;
        font-size: 12px;
        padding: 2px 9px;
        
        + span {
            margin-left: 8px;
        }
    }
`;

const StyledDetailsContainer = styled('div')`
    order: 1;
    width: 100%;
    position: relative;
    
    pre {
        display: inline;
        white-space: initial;
        font-family: inherit;
    }

    @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
        order: 0;
        width: 50%;
        min-width: 480px;
        padding-right: 40px;
    }
`;

const StyledDescriptionContainer = styled('div')`
    margin-bottom: 40px;
`;

const StyledPriceContainer = styled('div')`
    margin-bottom: 4rem;

    h4 {
        font-size: 2rem;
        margin-top: 2rem;
        margin-bottom: 0.33rem;
    }

    @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
        h4 {
            font-size: 2.5rem;
            margin-top: 4rem;
        }
    }
`;

const StyledInputContainer = styled('div')`
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    
    .quantity-select-container,
    .size-select-container {
        min-width: 115px;
        margin-right: 15px;

        @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
            min-width: 235px;
            margin-right: 20px;
        }
    }
    
    .button,
    a {
        padding: 15px 15px 14px 15px;

        @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
            padding: 15px 18px 14px 18px;
        }
        
        svg {
            width: 18px;
            height: 16px;
            margin-bottom: -2px;
            margin-left: 2px;
        }
    }
`;

const StyledProductInfo = styled('div')`
    h4 {
        margin-top: 2rem;
        margin-bottom: 0.5rem;
    }

    @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
        h4 {
            margin-top: 4rem;
        }
    }
`;

function Detail() {
    const { id } = useParams();
    const [product, setProduct] = React.useState(null);
    const [loadingBuyProduct, setLoadingBuyProduct] = React.useState(false);
    const [loadingMore, setLoadingMore] = React.useState(false);
    const [moreProducts, setMoreProducts] = React.useState([]);
    const [count, setCount] = React.useState(1);
    const [size, setSize] = React.useState("");
    const [showPopup, setShowPopup] = React.useState(false);
    const [showNotSamePaidType, setShowNotSamePaidType] = React.useState(false);
    const context = React.useContext(Context);

    React.useEffect(() => {
        const queryParameters = id !== undefined ? { id } : null;

        urlFetch('/api/products', { successCallback }, null, 'POST', queryParameters, { email: context.userEmail }, context.token);
    }, [id]); // eslint-disable-line

    const successCallback = (response) => {
        //redirect to start page if no product is returned
        if (response.elements.length === 0) {
            document.location.href = "/"
        }

        const responseProduct = response.elements[0];
        setProduct(responseProduct);

        if (responseProduct.categories.length === 0) {
            return;
        }

        const queryParameters = { category: responseProduct.categories[0].id, limit: 3, not: responseProduct.id }

        setLoadingMore(true);
        urlFetch('/api/products', { successCallback: successCallbackMore }, null, 'POST', queryParameters, { email: context.userEmail }, context.token);
    }

    const successCallbackMore = (response) => {
        setLoadingMore(false);
        setMoreProducts(response.elements);
    }

    if (product === null) {
        return <DefaultPage loading={true}/>;
    }

    const AddButton = (props) => {
        const { context, disabled } = props;

        if (!context.userEmail) {
            return <div>No user e-mail given</div>
        }

        const upsertToCartObject = (productId, count, size, is_self_paid) => {
            const newCart = Object.assign({}, context.cart);
            const items = Object.assign({}, newCart.items);
            items[productId] = {
                product_id: productId,
                count: count,
                size: size,
                is_self_paid: is_self_paid
            };

            newCart.items = items;

            return newCart;
        }

        const addToCart = () => {
            const data = upsertToCartObject(product.id, count, size, product.is_self_paid);
            setLoadingBuyProduct(true);
            const isSamePaidType = Object.values(context.cart.items).some(item => item.is_self_paid === product.is_self_paid);

            if (context.cart.items.length <= 0 || isSamePaidType) {
                urlFetch('/api/carts/update', { successCallback: (response) => { context.updateCart(response); setShowPopup(true); setLoadingBuyProduct(false);  } }, null, 'POST', null, { email: context.userEmail, data }, context.token);
                return;
            }
            setShowNotSamePaidType(true);
        }

        return (
            <Button disabled={disabled} loading={loadingBuyProduct}>
                <span className='interactive-link' onClick={addToCart}>Add to shopping cart <CartIcon/></span>
            </Button>
        )
    }

    const getCategoryTags = () => {
        let tags = [];

        for (let i = 0; i < product.categories.length; i++) {
            const category = product.categories[i];

            tags.push(
                <span key={`product-category-${i}`}>{category.title}</span>
            )
        }

        return tags;
    }

    const getMoreProducts = () => {
        let cards = [];

        for (let i = 0; i < moreProducts.length; i++) {
            const product = moreProducts[i];

            cards.push(
                <ProductCard key={`product-card-${i}`} product={product}/>
            )
        }

        return cards;
    }

    const priceInfoText = product.is_self_paid ? <>(incl. VAT. <a href={context.faqUrl} target='_blank' rel="noreferrer">More information.</a>)</> : '(per packaging unit)';

    return (
        <DefaultPage popupActive={showPopup}>
            <CustomComponentContainer>
                <ContentContainer>
                    <HomeLink/>

                    <StyledProductContainer>
                        <StyledDetailsContainer>
                            <StyledCategoryTagsContainer>{getCategoryTags()}</StyledCategoryTagsContainer>

                            <h1>{product.title}</h1>
                            <StyledDescriptionContainer dangerouslySetInnerHTML={{ __html: product.description }}/>

                            <StyledPriceContainer>
                                <h4>{product.price} €</h4>
                                {priceInfoText}
                            </StyledPriceContainer>

                            { product.sizes.length > 0 &&
                                <StyledInputContainer>
                                    <SizeSelect value={size} sizes={product.sizes} changeHandler={(e) => { setSize(e.currentTarget.value); }}/>
                                </StyledInputContainer>
                            }
                            <StyledInputContainer>
                                <QuantitySelect value={count} changeHandler={(e) => { setCount(e.currentTarget.value); }}/>
                                <div><AddButton disabled={product.sizes.length > 0 && !size} context={context}/></div>
                            </StyledInputContainer>

                            <StyledProductInfo>
                                <h4>Product information</h4>
                                <ProductInfoTable product={product}/>
                            </StyledProductInfo>
                        </StyledDetailsContainer>

                        <ImageSlider key={`image-slider-${product.id}`} imageMarkups={product.image.slider}/>
                    </StyledProductContainer>

                    { showPopup && <AddedProductPopup count={count} product={product} setShowPopup={setShowPopup} size={size}/> }
                    { showNotSamePaidType && <NotSamePaidTypePopup setShowPopup={setShowNotSamePaidType} />}
                </ContentContainer>
            </CustomComponentContainer>

            <MoreComponentContainer>
                <ContentContainer>
                    <h2>More in this category</h2>
                    <hr/>
                    <br/><br/>
                    <ProductCardsContainer>
                        { loadingMore ? <LoadingIcon/> : getMoreProducts() }
                    </ProductCardsContainer>
                </ContentContainer>
            </MoreComponentContainer>
        </DefaultPage>
    );
}

export default Detail;
